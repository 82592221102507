const TEAM_DB = [
  {
    id: '01',
    nameUa: 'Надія Євтушенко',
    nameEn: 'Nadiia Yevtushenko',
    position: 'Project Manager',
    linkedin: 'https://www.linkedin.com/in/yevtushenkonadiiapm/',
    pathToPhotoJPG: 'team/Nadiia-Yevtushenko.jpg',
    pathToPhotoWebP: 'team/Nadiia-Yevtushenko.webp',
  },
  {
    id: '02',
    nameUa: 'Софія Проців',
    nameEn: 'Sofia Protsiv',
    position: 'Team lead',
    linkedin: 'https://www.linkedin.com/in/sofiaaprotsiv/',
    pathToPhotoJPG: 'team/Sofia-Protsiv.jpg',
    pathToPhotoWebP: 'team/Sofia-Protsiv.webp',
  },
  {
    id: '03',
    nameUa: 'Богдан Яцик',
    nameEn: 'Bohdan Yatsyk',
    position: 'Developer',
    linkedin: 'https://www.linkedin.com/in/bogdan-yatsyk-61b302123/',
    pathToPhotoJPG: 'team/Bohdan-Yatsyk.jpg',
    pathToPhotoWebP: 'team/Bohdan-Yatsyk.webp',
  },
  {
    id: '04',
    nameUa: 'Іван Яровий',
    nameEn: 'Ivan Yarovyi',
    position: 'Developer',
    linkedin: 'https://www.linkedin.com/in/yarovyiivan/',
    pathToPhotoJPG: 'team/Ivan-Yarovyi.jpg',
    pathToPhotoWebP: 'team/Ivan-Yarovyi.webp',
  },
  {
    id: '05',
    nameUa: 'Олексій Пархоменко',
    nameEn: 'Oleksii Parkhomenko',
    position: 'Developer',
    linkedin: 'https://www.linkedin.com/in/oleksii-parkhomenko/',
    pathToPhotoJPG: 'team/Oleksii-Parkhomenko.jpg',
    pathToPhotoWebP: 'team/Oleksii-Parkhomenko.webp',
  },
  {
    id: '06',
    nameUa: 'Ольга Мещерякова',
    nameEn: 'Olha Meshcheriakova',
    position: 'Developer',
    linkedin: 'http://linkedin.com/in/olha-meshcheriakova',
    pathToPhotoJPG: 'team/Olha-Meshcheriakova.jpg',
    pathToPhotoWebP: 'team/Olha-Meshcheriakova.webp',
  },
  {
    id: '07',
    nameUa: 'Сергій Антоненко',
    nameEn: 'Sergii Antonenko',
    position: 'Developer',
    linkedin: 'https://www.linkedin.com/in/sergii-antonenko/',
    pathToPhotoJPG: 'team/Sergii-Antonenko.jpg',
    pathToPhotoWebP: 'team/Sergii-Antonenko.webp',
  },
  {
    id: '08',
    nameUa: 'Світлана Мірошниченко',
    nameEn: 'Svitlana Miroshnychenko',
    position: 'Developer',
    linkedin: 'https://www.linkedin.com/in/svitlana-miroshnychenko-12659a2b6/',
    pathToPhotoJPG: 'team/Svitlana-Miroshnychenko.jpg',
    pathToPhotoWebP: 'team/Svitlana-Miroshnychenko.webp',
  },
  {
    id: '09',
    nameUa: 'Вадим Трубай',
    nameEn: 'Vadim Trubay',
    position: 'Developer',
    linkedin: 'https://linkedin.com/in/vadim-trubay-146607257',
    pathToPhotoJPG: 'team/Vadim-Trubay.jpg',
    pathToPhotoWebP: 'team/Vadim-Trubay.webp',
  },
  {
    id: '10',
    nameUa: 'Валерія Іванова',
    nameEn: 'Valeriia Ivanova',
    position: 'Developer',
    linkedin: 'https://www.linkedin.com/in/valeriia-ivanova-6b66532b4/ ',
    pathToPhotoJPG: 'team/Valeriia-Ivanova.jpg',
    pathToPhotoWebP: 'team/Valeriia-Ivanova.webp',
  },
  {
    id: '11',
    nameUa: 'Юрій Качан',
    nameEn: 'Yurii Kachan',
    position: 'Developer',
    linkedin: 'https://www.linkedin.com/in/yurii-kachan/',
    pathToPhotoJPG: 'team/Yurii-Kachan.jpg',
    pathToPhotoWebP: 'team/Yurii-Kachan.webp',
  },
  {
    id: '12',
    nameUa: 'Лілія Бєлавцева',
    nameEn: 'Liliia Bielavtseva',
    position: 'QA',
    linkedin: 'https://www.linkedin.com/in/liliia-bielavtseva-463a54196',
    pathToPhotoJPG: 'team/Liliia-Bielavtseva.jpg',
    pathToPhotoWebP: 'team/Liliia-Bielavtseva.webp',
  },
  {
    id: '13',
    nameUa: 'Людмила Огородник',
    nameEn: 'Liudmyla Ohorodnyk ',
    position: 'QA',
    linkedin: 'https://www.linkedin.com/in/liuda-ohorodnyk/',
    pathToPhotoJPG: 'team/Liudmyla-Ohorodnyk.jpg',
    pathToPhotoWebP: 'team/Liudmyla-Ohorodnyk.webp',
  },
  {
    id: '14',
    nameUa: 'Тетяна Сорока',
    nameEn: 'Tetiana Soroka',
    position: 'QA',
    linkedin: 'https://www.linkedin.com/in/tasha-soroka/',
    pathToPhotoJPG: 'team/Tetiana-Soroka.jpg',
    pathToPhotoWebP: 'team/Tetiana-Soroka.webp',
  },
  {
    id: '15',
    nameUa: 'Віктор Кухарський',
    nameEn: 'Viktor Kukharskyi',
    position: 'QA',
    linkedin: 'https://www.linkedin.com/in/viktor-kukharskyi-39aa3529b',
    pathToPhotoJPG: 'team/Viktor-Kukharskyi.jpg',
    pathToPhotoWebP: 'team/Viktor-Kukharskyi.webp',
  },
];

export default TEAM_DB;
